import {actionCreatorFactory} from 'typescript-fsa';
import { Action as ReduxAction } from 'redux';
import { Lead, TransferStatus } from '../reducers/LeadReducer';
import { LeadFilters } from '../reducers/LeadFilterReducer';
import { Policy } from '../reducers/policy_reducer';
import { BulkReassignRequestDto } from '../reducers/advanced_search_reducer';
import H from 'history';
import { Contact } from '../reducers/ContactReducer';

const actionCreator = actionCreatorFactory('Lead');

// FETCH LEAD
export const GetLead = actionCreator.async<string, Lead>('GET_LEAD');

// UPDATE LEAD
export const UpdateLead = actionCreator.async<Lead, Lead>('UPDATE_LEAD');

// CREATE LEAD
export type LeadCreatePayload = {
	lead: Lead;
	history?: H.History;
	policy?: Policy;
	dupeCheck: boolean;
};
export const CreateLead = actionCreator.async<LeadCreatePayload, Lead>(
	'CREATE_LEAD'
);
export function createLead(
	leadToSend: Lead,
	policyToMatch?: Policy,
	history?: H.History,
	dupeCheck?: boolean
): ReduxAction {
	let payload: LeadCreatePayload = {
		lead: leadToSend,
		policy: policyToMatch,
		history: history,
		dupeCheck: dupeCheck === undefined ? true : dupeCheck
	};
	return CreateLead.started(payload);
}

// DELETE LEAD
export const DeleteLead = actionCreator.async<string, string>('DELETE_LEAD');

export const GetDashboardCounts = actionCreator.async<void, void>('GET_DASHBOARD_COUNTS');

// FETCH STATUS COUNTS
export const GetStatusCounts = actionCreator.async<
	void,
	{ [key: string]: number }
	>('GET_STATUS_COUNTS');

export const GetHomeStatusCounts = actionCreator.async<
	void,
	{ [key: string]: number }
	>('GET_HOME_STATUS_COUNTS');

// FETCH NOT CONTACTED LEAD
export const GetNotContactedLeadCount = actionCreator.async<undefined, number>(
	'GET_NOT_CONTACTED_LEAD_COUNT'
);

// STORE FILTERS
export const StoreLeadFilters = actionCreator<LeadFilters>(
	'STORE_LEAD_FILTERS'
);

export interface ToggleLeadConnectLeadsParams {
	agentId: string;
	type: string;
	status: TransferStatus;
}
export const ToggleLeadConnectLeads = actionCreator<ToggleLeadConnectLeadsParams>('TOGGLE_LEAD_CONNECT_LEADS');

export interface GetLeadConnectStatusParams {
	agentId: string
}
export const GetLeadConnectStatus = actionCreator<GetLeadConnectStatusParams>(
	'GET_LEAD_CONNECT_STATUS'
);

export const StoreLeadConnectStatus = actionCreator<TransferStatus>(
	'STORE_LEAD_CONNECT_STATUS'
);

export const StoreLiveTransferStatus = actionCreator<TransferStatus>(
	'STORE_LIVE_TRANSFER_STATUS'
);

export interface GetFilteredLeadsPayload {
	ratingFilter: string;
	statusFilterLabels: string[];
	lineOfBusinessFilter: string;
	vendorFilter: string;
	inputSourceFilter: string[];
	campaignFilter: string;
	dateTypeFilter: string;
	sortBy: string;
	fromDate?: Date;
	toDate?: Date;
	filterBySearch: string;
	sortCategory: string;
	sortDirection: string;
	pageNum: number;
	pageSize: number;
	leadTypeFilter: string[];
	callAttempts: string;
}
// GET FILTERED LEADS
export const GetFilteredLeads = actionCreator.async<
	GetFilteredLeadsPayload,
	Lead[]
	>('GET_FILTERED_LEADS');

// REASSIGN B-LEAD
export interface ReassignBLeadParams {
	householdId: string;
	agentCode: string;
	agentName: string
}
export const ReassignBLead = actionCreator.async<
	ReassignBLeadParams,
	boolean
	>('REASSIGN_B_LEAD');

// LEAD BULK REASSIGN
export const BulkReassign = actionCreator.async<BulkReassignRequestDto, string>(
	'BULK_REASSIGN'
);

// STORE INACTIVE LEAD ID
export const StoreInactiveLeadId = actionCreator<string>(
	'STORE_INACTIVE_LEAD_ID'
);

// Toggle multi select in Lead List
export const ToggleLeadListMultiSelect = actionCreator<boolean>('TOGGLE_LEAD_LIST_MULTI_SELECT');
export const SelectLeadListItem = actionCreator<Lead>('SELECT_LEAD_LIST_ITEM');
export const ResetLeadListIndex = actionCreator('RESET_LEAD_LIST_INDEX');
export const ClearSelectedLeads = actionCreator('CLEAR_SELECTED_LEADS');
export const StoreSelectedLeadContacts = actionCreator<Contact[][]>('STORE_SELECTED_LEAD_CONTACTS');
export const IncrementSelectedLeadIndex = actionCreator('INCREMENT_SELECTED_LEAD_INDEX');
export const CheckLeadListIndex = actionCreator('CHECK_LEAD_LIST_INDEX');

