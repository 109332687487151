import React from 'react';
import ActivitiesSvg from './icons/activities';
import AnnouncementsSvg from './icons/announcements';
import BattlesSvg from './icons/battles';
import ClientConnectSvg from './icons/client_connect';
import CrmSvg from './icons/crm';
import DocumentsSvg from './icons/documents';
import FinanceSvg from './icons/finance';
import ImportantSvg from './icons/important';
import LeadConfigSvg from './icons/lead_config';
import LeadsSvg from './icons/leads';
import NotificationsSvg from './icons/notifications';
import LicenseAppointmentSvg from './icons/licenseAppointment';
import PerformanceSvg from './icons/performance';
import ProfileSvg from './icons/profile';
import QuoteSvg from './icons/quote';
import SettingsSvg from './icons/settings';
import TeamViewSvg from './icons/teamview';
import ToolsSvg from './icons/tools';
import TrainingSvg from './icons/training';
import { themePalette } from '../../utilities/branding';
import PodcastSvg from './icons/podcast';
import { FactFinderIconSvg } from '@optum-uhone-hmkts/rise';
import { DEFAULT_SIZE } from '.';
import CommissionStatementSvg from './icons/commissionStatement';

export enum IconType {
    Activities = 'activities',
    Announcements = 'announcements',
    Battle = 'battle',
    Battles = 'battles',
    ClientConnect = 'clientconnect',
    ClientConnectOLD = 'clientConnect',
    Crm = 'crm',
    Documents = 'documents',
    FactFinder = 'factfinder',
    Finances = 'finances',
    Finance = 'finance',
    General = 'general',
    Important = 'important',
    LeadConfig = 'leadconfig',
    Leads = 'leads',
    News = 'news',
    Notifications = 'notifications',
    Notification = 'notification',
    LicenseAppointment = 'licenseAppointment',
    CommissionStatement = 'commissionStatement',
    Performance = 'performance',
    Podcast = 'podcast',
    Profile = 'profile',
    QuickQuote = 'quickquote',
    Quote = 'quote',
    Connecture = 'connecture',
    Settings = 'settings',
    Tasks = 'tasks',
    Task = 'task',
    TeamView = 'teamview',
    Todo = 'todo',
    TodoList = 'todolist',
    TodoListOLD = 'todoList',
    Tools = 'tools',
    Training = 'training',
    Urgent = 'urgent',
}

interface IconProps {
    type: IconType | string;
    size?: number;
    color?: string;
    style?: React.CSSProperties;
}

export const SvgIcon: React.FC<IconProps> = ({ type, size = DEFAULT_SIZE, color, style }) => {
    switch (type) {
        case IconType.Important:
        case IconType.Urgent:
            return (
                <ImportantSvg
                    fill={color || themePalette.required_text}
                    width={size}
                    height={size}
                    style={style}
                />
            );
        case IconType.Tasks:
        case IconType.Task:
        case IconType.Todo:
        case IconType.TodoList:
        case IconType.TodoListOLD:
        case IconType.Activities:
            return (
                <ActivitiesSvg
                    fill={color || themePalette.menuSvgActivities}
                    width={size}
                    height={size}
                    style={style}
                />
            );
        case IconType.General:
        case IconType.Notifications:
        case IconType.Notification:
            return (
                <NotificationsSvg
                    fill={color || themePalette.menuSvgNotifications}
                    width={size}
                    height={size}
                    style={style}
                />
            );
        case IconType.Announcements:
        case IconType.News:
            return (
                <AnnouncementsSvg
                    fill={color || themePalette.menuSvgAnnouncments}
                    width={size}
                    height={size}
                    style={style}
                />
            );
        case IconType.Tools:
            return (
                <ToolsSvg
                    fill={color || themePalette.menuSvgTools}
                    width={size}
                    height={size}
                    style={style}
                />
            );
        case IconType.Battles:
        case IconType.Battle:
            return (
                <BattlesSvg
                    fill={color || themePalette.menuSvgBattles}
                    width={size}
                    height={size}
                    style={style}
                />
            );
        case IconType.LicenseAppointment:
            return (
                <LicenseAppointmentSvg
                    fill={color || themePalette.menuSvgLicenseAppointment}
                    width={size}
                    height={size}
                    style={style}
                />
            );
        case IconType.CommissionStatement:
            return (
                <CommissionStatementSvg
                    fill={color || themePalette.menuSvgCommissionStatement}
                    width={size}
                    height={size}
                    style={style}
                />
            );
        case IconType.Performance:
            return (
                <PerformanceSvg
                    fill={color || themePalette.menuSvgPerformance}
                    width={size}
                    height={size}
                    style={style}
                />
            );            
        case IconType.TeamView:
            return (
                <TeamViewSvg
                    fill={color || themePalette.menuSvgTeamView}
                    width={size}
                    height={size}
                    style={style}
                />
            );
        case IconType.Finances:
        case IconType.Finance:
            return (
                <FinanceSvg
                    fill={color || themePalette.menuSvgFinance}
                    width={size}
                    height={size}
                    style={style}
                />
            );
        case IconType.Training:
            return (
                <TrainingSvg
                    fill={color || themePalette.menuSvgTraining}
                    width={size}
                    height={size}
                    style={style}
                />
            );
        case IconType.Documents:
            return (
                <DocumentsSvg
                    fill={color || themePalette.menuSvgDocuments}
                    width={size}
                    height={size}
                    style={style}
                />
            );
        case IconType.Podcast:
            return (
                <PodcastSvg
                    fill={color || themePalette.menuSvgPodcast}
                    width={size}
                    height={size}
                    style={style}
                />
            );
        case IconType.Profile:
            return (
                <ProfileSvg
                    fill={color || themePalette.menuSvgProfile}
                    width={size}
                    height={size}
                    style={style}
                />
            );
        case IconType.Settings:
            return (
                <SettingsSvg
                    fill={color || themePalette.menuSvgSettings}
                    width={size}
                    height={size}
                    style={style}
                />
            );
        case IconType.LeadConfig:
            return (
                <LeadConfigSvg
                    fill={color || themePalette.menuSvgLeads}
                    width={size}
                    height={size}
                    style={style}
                />
            );
        case IconType.Leads:
            return (
                <LeadsSvg
                    fill={color || themePalette.menuSvgLeads}
                    width={size}
                    height={size}
                    style={style}
                />
            );
        case IconType.FactFinder:
            return (
                <FactFinderIconSvg
                    color={color || themePalette.menuSvgFactFinder}
                    width={size}
                    height={size}
                    style={style}
                    viewBox="0 0 30 30"
                />
            );
        case IconType.Crm:
            return (
                <CrmSvg
                    fill={color || themePalette.menuSvgCRM}
                    width={size}
                    height={size}
                    style={style}
                />
            );
        case IconType.QuickQuote:
        case IconType.Quote:
            return (
                <QuoteSvg
                    fill={color || themePalette.menuSvgQuotes}
                    width={size}
                    height={size}
                    style={style}
                />
            );
        case IconType.Connecture:
            return (
                <QuoteSvg
                    fill={color || themePalette.menuSvgConnecture}
                    width={size}
                    height={size}
                    style={style}
                />
            );
        case IconType.ClientConnect:
        case IconType.ClientConnectOLD:
            return (
                <ClientConnectSvg
                    fill={color || themePalette.menuSvgClientConnect}
                    width={size}
                    height={size}
                    style={style}
                />
            );
        default:
            return (
                <AnnouncementsSvg
                    fill={color || themePalette.menuSvgAnnouncments}
                    width={size}
                    height={size}
                    style={style}
                />
            );
    }
};