import React, { useEffect } from 'react';
import { NavigationProps } from '../../components/nav/Routes';
import { BasePageContainer } from '../../components/Layout/BasePage';
import { HeaderBarComponent } from '../../components/Layout/HeaderBar';
import { connect } from '@optum-uhone-hmkts/rise';
import { PersistentNotifications } from '../../components/notifications/notificationPersistent';
import {
	GetLeadConnectStatus,
	ToggleLeadConnectLeads,
} from '../../actions/lead_actions';
import { TransferStatus } from '../../reducers/LeadReducer';
import { LeadTransferStatus } from '../../components/lead/lead_transfer_status';
import {
	notificationCategories,
	NotificationSummary,
} from '../../components/notifications/notificationSummary';
import { WideButton } from '../../components/utility/wide_button';
import { Icon } from '@material-ui/core';
import { navigateTo } from '../../actions/navigation_actions';
import { createHelpAction, MoreMenu } from '../../components/nav/more_menu';
import { themeLinks } from '../../utilities/branding';
import { jwt_auth } from '../../utilities/auth';
import { P } from '../../utilities/auth/permissions';
import { makeOpenExternalLink } from '../../utilities';
import { DesktopPadding } from '../../components/Layout/desktop_padding';
import { AppState } from '../../reducers';
import { Dispatch } from '@reduxjs/toolkit';
import { AppConfig } from '../../types/config';

interface StateProps {
	isReceiveLiveTransferLeads: TransferStatus;
	isReceiveLeadConnectLeads: TransferStatus;
	agentCode: string;
	isLoading: boolean;
	allowManageLeadOrders: boolean;
}
interface DispatchProps {
	navigateTo(route: string): void;
	getLeadConnectStatus: (agentCode: string) => void;
	toggleLeadConnectLeads: (
		type: string,
		isReceiving: TransferStatus,
		agentCode: string
	) => void;
}
type Props = StateProps & DispatchProps & NavigationProps;

const LeadCirclePage: React.FC<Props> = (props) => {

	const { 
		agentCode, isLoading, getLeadConnectStatus, toggleLeadConnectLeads,
		isReceiveLeadConnectLeads, isReceiveLiveTransferLeads, allowManageLeadOrders
	} = props;
	useEffect(() => {
		getLeadConnectStatus(agentCode);
	}, []);

	return (
		<BasePageContainer
			topComponent={
				<HeaderBarComponent
					title="Leads"
					isLoading={isLoading}
					rightButtons={<MoreMenu children={[createHelpAction(themeLinks.helpLinkLeads)]} />}
				/>
			}
		>
			<PersistentNotifications />
			<NotificationSummary categorySelector={notificationCategories.leads} />
			<DesktopPadding>
				<LeadTransferStatus
					isReceivingLeadConnectLeads={isReceiveLeadConnectLeads}
					isReceivingLiveTransferLeads={isReceiveLiveTransferLeads}
					toggleLeadConnectLeads={(isReceiving: TransferStatus) =>
						toggleLeadConnectLeads('Global', isReceiving, agentCode)
					}
					toggleLiveTransferLeads={(isReceiving: TransferStatus) =>
						toggleLeadConnectLeads('LiveTransfer', isReceiving, agentCode)
					}
					canUpdate={jwt_auth.hasPermission(P.LeadConnectUpdate) && !isLoading}
				/>
				{allowManageLeadOrders && (
					<WideButton
						primary={'LeadConnect'}
						secondary={'Manage Lead Orders'}
						icon={<Icon style={{ marginRight: 0 }}>open_in_new</Icon>}
						onClick={makeOpenExternalLink(AppConfig.lead_connect_link)}
					/>
				)}
			</DesktopPadding>
		</BasePageContainer>
	);

}

const mapStateToProps = (state: AppState): StateProps => ({
	agentCode: state.agent.agentCode,
	isReceiveLiveTransferLeads: state.lead.isReceivingLiveTransferLeads,
	isReceiveLeadConnectLeads: state.lead.isReceivingLeadConnectLeads,
	isLoading: state.lead.isLoading,
	allowManageLeadOrders: state.agent.agency !== 'FirstFamily',
});
const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
	navigateTo: (route: string) => dispatch(navigateTo(route)),
	getLeadConnectStatus: (agentCode: string) =>
		dispatch(GetLeadConnectStatus({ agentId: agentCode })),
	toggleLeadConnectLeads: (type: string, isReceiving: TransferStatus, agentCode: string) =>
		dispatch(
			ToggleLeadConnectLeads({
				agentId: agentCode,
				type: type,
				status: isReceiving,
			})
		),
});

export const LeadCirclePageContainer = connect(
	mapStateToProps,
	mapDispatchToProps, true
)(LeadCirclePage);
